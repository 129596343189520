import { yupResolver } from '@hookform/resolvers/yup';
import { GridRowParams } from '@material-ui/data-grid';
import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';
import {
  InputNumber2,
  InputPassword,
  InputSelect,
  InputText,
  InputText2,
} from '~/components/NovosInputs';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import api from '~/services/api';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { MdCheck, MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';

import { ButtonReturnCards, Container, ContainerLoading } from './styles';
import { GrupoAplicacao, SelectProps } from './types';
import { schema } from './validations';

const CadastroDeAplicacao: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [flagInativo, setFlagInativo] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [defaultOptions, setDefaultOptions] = useState<any[]>([]);
  const [nomeEspaco, setNomeEspaco] = useState<any>('');
  const [statusCheck, setStatusCheck] = useState<boolean>(false);
  const [validabd, setValidabd] = useState<boolean>(false);
  const [loadValidabd, setLoadValidabd] = useState<boolean>(false);
  const [msgErro, setMsgErro] = useState<any>('');

  useEffect(() => {
    const getGrupoAplicacao = async () => {
      const res = await api.get(`/grupo-de-aplicacao/`);
      const { success, data, message } = res.data;

      if (!success) throw new Error(message);

      const options = data.map((grupo: GrupoAplicacao) => ({
        label: grupo.des_nome,
        value: grupo.cod_grupo,
        url: grupo.des_base_url,
        flg_inativo: grupo.flg_inativo,
      }));

      setDefaultOptions(options);
    };
    getGrupoAplicacao();
  }, [isUpdate]);

  useEffect(() => {
    const { cod_grupo } = getValues();

    if (cod_grupo) {
      const aplicacao_grupo = defaultOptions.filter(
        (item: any) => item.value === cod_grupo.value,
      );

      if (aplicacao_grupo.length > 0) {
        if (nomeEspaco === '')
          setValue('des_url', `https://${aplicacao_grupo[0].url}`);
        else
          setValue(
            'des_url',
            `https://${nomeEspaco}.${aplicacao_grupo[0].url}`,
          );
      } else {
        setValue('des_url', `https://${nomeEspaco}`);
      }
    }
  }, [nomeEspaco]);

  const formBlank = {
    des_nome: '',
    des_nome_espaco: '',
    des_url: '',
    des_bd_endpoint: '',
    des_bd_porta: '',
    des_bd_nome: '',
    des_bd_usuario: '',
    des_bd_senha: '',
    flg_inativo: false,
    flg_validado_conexao: false,
    cod_grupo: { label: 'Selecione', value: undefined },
  };

  const onRowClick = async (param: GridRowParams) => {
    setLoader(true);
    const { row } = param;
    const {
      des_nome,
      des_nome_espaco,
      des_url,
      des_bd_endpoint,
      des_bd_porta,
      des_bd_nome,
      des_bd_usuario,
      flg_inativo,
      flg_validado_conexao,
      cod_aplicacao,
      des_nome_grupo,
    } = row;

    const codGrupo = defaultOptions.filter(
      (item) => item.label === des_nome_grupo,
    );

    reset({
      des_nome,
      des_nome_espaco,
      des_url,
      des_bd_endpoint,
      des_bd_porta,
      des_bd_nome,
      des_bd_usuario,
      flg_validado_conexao,
    });

    setValue('cod_grupo', {
      value: codGrupo[0].value,
      label: codGrupo[0].label,
    });

    setValidabd(flg_validado_conexao);
    setStatusCheck(flg_validado_conexao);
    setFlagInativo(flg_inativo);
    setValue('cod_aplicacao', cod_aplicacao);
    setUpdate(true);
    setValue('isUpdate', true);
    setShowSearch(false);
    setLoader(false);
  };

  const resetFormData = () => {
    setValidabd(false);
    setFlagInativo(false);
    reset(formBlank);
    setUpdate(false);
    setValue('isUpdate', false);
  };

  const onSubmit = handleSubmit(async (data) => {
    delete data.isUpdate;
    if (isUpdate) {
      if (!data.des_bd_senha) {
        delete data.des_bd_senha;
      }
      const res = await api.put(
        `/cadastro-de-aplicacao/${data.cod_aplicacao}`,
        {
          ...data,
        },
      );

      const { success, message } = res.data;

      if (!success) {
        return toast.warning(message);
      }

      if (success) {
        resetFormData();
        setShowSearch(true);
        return toast.success(message);
      }
    }
    const res = await api.post('/cadastro-de-aplicacao', data);

    const { success, message } = res.data;

    if (!success) {
      return toast.warning(message);
    }

    resetFormData();
    setShowSearch(false);
    return toast.success(message);
  });

  const handleDesUrl = async (selected?: any) => {
    const values = getValues();
    const { des_nome_espaco } = values;

    let newUrl;
    if (des_nome_espaco) {
      newUrl = `https://${des_nome_espaco.toLowerCase()}.${selected.url}`;
    } else {
      newUrl = `https://${selected.url}`;
    }
    setValue('des_url', newUrl);
  };

  const handleDelete = async () => {
    const { cod_aplicacao } = getValues();
    const res = await api.delete(`/cadastro-de-aplicacao/${cod_aplicacao}`);
    const { success, message } = res.data;
    if (!success) {
      return toast.warning(message);
    }
    toast.success(message);
    resetFormData();
    setShowSearch(true);
  };

  const onNewData = () => {
    resetFormData();
    setValidabd(false);
    setShowSearch(false);
  };

  const handleConexao = async () => {
    const values = getValues();
    const {
      des_bd_porta,
      des_bd_senha,
      des_bd_usuario,
      des_bd_nome,
      des_bd_endpoint,
      cod_aplicacao,
    } = values;
    if (
      des_bd_porta === '' ||
      des_bd_usuario === '' ||
      des_bd_nome === '' ||
      des_bd_endpoint === ''
    ) {
      return toast.warning(
        'Antes de testar a conexão preencha todos os campos',
      );
    }
    setLoadValidabd(true);

    const body = {
      cod_aplicacao,
      des_bd_porta,
      des_bd_senha,
      des_bd_usuario,
      des_bd_nome,
      des_bd_endpoint,
    };

    if (!des_bd_senha) {
      delete body.des_bd_senha;
    }

    try {
      const res = await api.post('/checar-conexao', body);

      const { success, message } = res.data;
      if (success) {
        setValidabd(true);
        setValue('flg_validado_conexao', true);
        setStatusCheck(true);
      }
    } catch (e: any) {
      // setMsgErro(e.data.message);
      // setValidabd(true);
      setStatusCheck(false);
    }
    setLoadValidabd(false);
  };

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search codTela={201} newData={onNewData} onRowClick={onRowClick} />
      )}

      {!showSearch && (
        <FormDefault
          codTela={201}
          title="Cadastro de Aplicação"
          codigoRegistro={[
            { value: getValues('cod_aplicacao'), des_campo: 'Código' },
          ]}
          onSave={onSubmit}
          onCancel={() => setShowSearch(true)}
          isUpdate={isUpdate}
          onNew={resetFormData}
          onDelete={handleDelete}
          onClearFields={resetFormData}
          onReturnSearch={() => {
            resetFormData();
            setShowSearch(true);
          }}
        >
          <Row>
            <Col sm={12} md={6}>
              <InputText
                maxLength={100}
                label="Descrição"
                placeholder="Digite aqui a Descrição"
                name="des_nome"
                caseInput="upper"
                register={register}
                control={control}
                isError={!!errors.des_nome}
              />
            </Col>
            <Col sm={12} md={5}>
              <InputText2
                maxLength={50}
                label="Nome do Espaço"
                placeholder="Informe o Nome do Espaço"
                control={control}
                name="des_nome_espaco"
                register={register}
                isError={!!errors.des_nome_espaco}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const lastLetter = event.target.value.slice(-1);
                  const regex = /^[a-zA-Z0-9]+$/;
                  if (regex.test(lastLetter) || lastLetter === '') {
                    setValue(
                      'des_nome_espaco',
                      event.target.value.toLowerCase(),
                    );
                    setNomeEspaco(event.target.value);
                  }
                }}
              />
            </Col>
            <Col sm={12} md={1}>
              <ToggleDefault
                labelText="Inativo?"
                setChecked={flagInativo}
                onSwitch={() => {
                  setValue('flg_inativo', !flagInativo);
                  setFlagInativo(!flagInativo);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={4}>
              <InputSelect
                label="Grupo de Aplicação"
                placeholder="Selecione..."
                maxLength={50}
                name="cod_grupo"
                register={register}
                isError={!!errors.cod_grupo}
                control={control}
                options={defaultOptions.filter(
                  (v: any) => v.flg_inativo !== true,
                )}
                changeSelected={(selected: any) => {
                  setValue('cod_grupo', selected);
                  handleDesUrl(selected);
                }}
              />
            </Col>
            <Col sm={12} md={8} style={{ color: '#A5ADBA' }}>
              <InputText
                maxLength={1000}
                label="URL"
                placeholder=""
                disabled
                name="des_url"
                register={register}
                control={control}
                isError={!!errors.des_url}
              />
            </Col>
          </Row>
          <Separator labelText="Configurações do BD" />
          <Row>
            <Col sm={12} md={6}>
              <InputText
                maxLength={200}
                label="Endpoint"
                placeholder=""
                name="des_bd_endpoint"
                register={register}
                control={control}
                isError={!!errors.des_bd_endpoint}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setValue('flg_validado_conexao', false);
                  setValue('des_bd_endpoint', event.target.value);
                }}
              />
            </Col>
            <Col sm={12} md={2}>
              <InputNumber2
                label="Porta"
                placeholder=""
                maxLength={5}
                max={65535}
                min={0}
                control={control}
                name="des_bd_porta"
                register={register}
                isError={!!errors.des_bd_porta}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (Number(event.target.value) <= 65535)
                    setValue('des_bd_porta', event.target.value);
                }}
              />
            </Col>
            <Col sm={12} md={4}>
              <InputText
                maxLength={50}
                label="Nome"
                placeholder=""
                name="des_bd_nome"
                register={register}
                control={control}
                isError={!!errors.des_bd_nome}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setValue('flg_validado_conexao', false);
                  setValue('des_bd_nome', event.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputText
                maxLength={50}
                label="Usuário"
                placeholder=""
                name="des_bd_usuario"
                register={register}
                control={control}
                isError={!!errors.des_bd_usuario}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setValue('flg_validado_conexao', false);
                  setValue('des_bd_usuario', event.target.value);
                }}
              />
            </Col>
            <Col sm={12} md={4}>
              <InputPassword
                maxLength={200}
                label="Senha"
                placeholder=""
                name="des_bd_senha"
                register={register}
                isError={!!errors.des_bd_senha}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setValue('flg_validado_conexao', false);
                  setValue('des_bd_senha', event.target.value);
                }}
              />
            </Col>
            <Col sm={12} md={3}>
              {loadValidabd ? (
                <ContainerLoading>
                  <DefaultLoader />
                </ContainerLoading>
              ) : (
                <ButtonReturnCards
                  onClick={handleConexao}
                  type="button"
                  className="addNew"
                >
                  Testar conexão
                </ButtonReturnCards>
              )}
            </Col>
          </Row>
          {validabd && (
            <>
              {statusCheck && (
                <Row
                  style={{
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '30px',
                    backgroundColor: '#cadef9',
                  }}
                >
                  <Col style={{ padding: '30px' }}>
                    <MdCheck style={{ fontSize: '30px', color: '#009e11' }} />
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        paddingLeft: '5px',
                      }}
                    >
                      Conexão validada com sucesso!
                    </span>
                  </Col>
                </Row>
              )}
              {!statusCheck && (
                <Row
                  style={{
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginTop: '30px',
                    backgroundColor: '#f8d7da',
                  }}
                >
                  <Col style={{ padding: '30px' }}>
                    <MdClose style={{ fontSize: '30px', color: 'red' }} />
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        paddingLeft: '5px',
                      }}
                    >
                      Falha ao efetuar conexão: {msgErro}
                    </span>
                  </Col>
                </Row>
              )}
            </>
          )}
        </FormDefault>
      )}
    </Container>
  );
};

export default CadastroDeAplicacao;
